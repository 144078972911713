import React from 'react';

import cloudinary from '../../utils/cdn';

export default function LazyImage({ name, alt }) {
  return (
    <img
      data-sizes="auto"
      data-src={cloudinary(name)}
      alt={alt}
      className="lazyload"
    />
  );
}
