import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/SEO';
import Banner from '../components/Elements/Banner';
import BackToTop from '../components/Elements/BackToTop';
import { ListSimple } from '../components/Post/PostList';
import Container from '../components/Layout/Container';

const StyledSection = styled.section`
  margin-bottom: 1.5rem;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-divider);
  }
`;

function yearsBreakdown(recipes) {
  return recipes.reduce((acc, recipe) => {
    if (!recipe.created_at) {
      return acc;
    }

    const [year] = recipe.created_at.split('-');
    const existingYear = acc[year];

    if (existingYear) {
      existingYear.count += 1;
      existingYear.recipes.push(recipe);
      return acc;
    }

    acc[year] = {
      count: 1,
      recipes: [recipe],
    };

    return acc;
  }, {});
}

export default function RecipesPage({ data }) {
  const recipes = data.recipes.nodes || [];
  const breakdown = yearsBreakdown(recipes);
  const years = Object.keys(breakdown).sort((a, b) => b - a);

  return (
    <div>
      <SEO title="Buscador de recetas">
        <meta name="robots" content="NOINDEX, NOFOLLOW" />
      </SEO>
      <Banner header="Todas nuestras recetas" />
      <Container>
        {years.map((year) => (
          <StyledSection key={year}>
            <h2>{year}</h2>
            <ListSimple title={year} recipes={breakdown[year].recipes} />
            <BackToTop />
          </StyledSection>
        ))}
      </Container>
    </div>
  );
}

export const query = graphql`
  query {
    recipes: allRecipesJson(sort: { fields: created_at, order: DESC }) {
      nodes {
        slug
        description
        title
        time
        people
        price
        created_at
        categories
        images {
          name
          type
          order
          title
          description
        }
      }
    }
  }
`;
