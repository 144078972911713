import React from 'react';
import styled from 'styled-components';
import {
  FaCalendarAlt, FaRegHourglass, FaTags, FaCoins, FaUsers,
} from 'react-icons/fa';
import { Link } from 'gatsby';

const StyledMeta = styled.div`
  font-size: .75rem;
  color: var(--color-muted);

  p {
    margin: 0.25rem 0;
  }

  a {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 8px;
    padding: 5px 10px;
    text-decoration: none;
    border: 1px solid #b6b6b6;
    border-radius: 4px;
  }
`;

export default function PostMeta({
  date, time, people, price, categories,
}) {
  return (
    <StyledMeta>
      <p><strong><FaTags /> Categorías:</strong> {categories.map((category) => (<Link key={category} to={`/recetas/${category}`}>{category}</Link>))}</p>
      <p><strong><FaRegHourglass /> Tiempo: </strong><span>{`${time}'`}</span></p>
      {people && <p><strong><FaUsers /> Comensales: </strong><span>{people}</span></p>}
      {price && <p><strong><FaCoins /> Precio: </strong><span>{`${price}€`}</span></p>}
      <p><strong><FaCalendarAlt /> Publicado: </strong> <time>{date}</time></p>
    </StyledMeta>
  );
}
