import React from 'react';
import styled from 'styled-components';
import { PostSimple, PostThumbnail } from './Post';

const StyledList = styled.ol`
  .post {
    margin-bottom: 1.5rem;
  }
`;

const StyledListWithDivider = styled.ol`
  .post {
    margin-bottom: 1.5rem;
  }

  .post:not(:last-child){
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--color-divider);
  }
`;

export function ListSimple({ recipes }) {
  return (
    <StyledList>
      {recipes.map((recipe) => (
        <li className="post" key={recipe.slug}>
          <PostSimple recipe={recipe} />
        </li>
      ))}
    </StyledList>
  );
}

export function ListThumb({ recipes }) {
  return (
    <StyledListWithDivider>
      {recipes.map((recipe) => (
        <li className="post" key={recipe.slug}>
          <PostThumbnail recipe={recipe} />
        </li>
      ))}
    </StyledListWithDivider>
  );
}
