import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PostMeta from './PostMeta';
import LazyImage from '../Image/LazyImage';

const StyledPostSimple = styled.article`
  h2 {
    margin: 0 0 1rem 0;
  }
`;

const StyledPostThumb = styled.article`
  img {
    margin-bottom: .75rem;
  }

  h2 {
    margin: 0 0 1rem 0;
  }
`;

export function PostSimple({ recipe }) {
  return (
    <StyledPostSimple>
      <Link to={`/recetas/${recipe.slug}`}>
        <h2>{recipe.title}</h2>
      </Link>
      <p>{recipe.description}</p>
      <PostMeta
        date={recipe.created_at}
        time={recipe.time}
        price={recipe.price}
        people={recipe.people}
        categories={recipe.categories}
      />
    </StyledPostSimple>
  );
}

export function PostThumbnail({ recipe }) {
  const images = recipe.images.filter((image) => image.type === 1);
  const thumbnail = images[0];

  return (
    <StyledPostThumb>
      <div className="post__thumb">
        <Link to={`/recetas/${recipe.slug}`}>
          <LazyImage name={thumbnail.name} alt={thumbnail.title} />
        </Link>
      </div>
      <div className="post__content">
        <Link to={`/recetas/${recipe.slug}`}>
          <h2>{recipe.title}</h2>
        </Link>
        <p>{recipe.description}</p>
        <PostMeta
          date={recipe.created_at}
          time={recipe.time}
          price={recipe.price}
          people={recipe.people}
          categories={recipe.categories}
        />
      </div>
    </StyledPostThumb>
  );
}
