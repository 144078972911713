import React from 'react';
import styled from 'styled-components';
import Container from '../Layout/Container';

const StyledBanner = styled.div`
  padding: 2.5rem 0;
  margin: 1.5rem 0;
  text-align: center;
  color: var(--color-label-fg);
  background-color: var(--color-label-bg);

  h2 {
    font-size: 2.5rem;
    margin: 0;
  }
`;

export default function Banner({ header, subHeader }) {
  return (
    <StyledBanner>
      <Container>
        <h2>{header}</h2>
        {subHeader && <h3>{subHeader}</h3>}
      </Container>
    </StyledBanner>
  );
}
