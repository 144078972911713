import React from 'react';
import styled from 'styled-components';

const StyledLink = styled.a`
  display: block;
  clear: both;
  font-size: 0.6rem;
  text-transform: uppercase;
  text-align: right;
  text-decoration: none;
  margin-bottom: 10px;
`;

export default function BackToTop() {
  function handleClick(event) {
    event.preventDefault();
    window.scroll(0, 0);
  }

  return (
    <StyledLink onClick={handleClick}>Subir arriba ↑</StyledLink>
  );
}
